import type { SpidSettings } from "../../../../../types/settings";
import type { MenuItem } from "../../MenuItem/MenuItem";

import { getBrandSpecificText } from "../../../../../utils/getBrandSpecificText";
import { MenuGroup } from "../MenuGroup";
import { logout } from "../../../../../utils/auth/logout";
import { login } from "../../../../../utils/auth/login";
import { AppearanceMenuItem } from "./AppearanceMenuItem";

const UserMenuGroup = ({
  isLoggedIn,
  isPremium,
  userSettings,
}: {
  isLoggedIn: boolean;
  isPremium: boolean;
  userSettings?: SpidSettings;
}) => {
  const isOmniEkonomi = process.env.NEXT_PUBLIC_UI_THEME === "ekonomi";
  const { name, email } = userSettings || {};
  const newsMix = isLoggedIn
    ? [
        {
          icon: "newsmix",
          text: "Din nyhetsmix",
          type: "link",
          href: "/nyhetsmix",
        },
      ]
    : [];
  const membership = {
    icon: "membership",
    text: "Ditt medlemskap",
    href: "https://omni.se/minsida/medlemskap",
    type: "link",
    isExternalLink: true,
  };
  const account = {
    icon: "membership",
    text: "Ditt konto",
    href: "https://omni.se/minsida",
    type: "link",
    showSchibstedLogo: true,
    isExternalLink: true,
  };
  const becomeMember = {
    icon: "membership",
    text: isOmniEkonomi ? "Bli premiummedlem" : "Bli medlem i Omni Mer",
    href: isOmniEkonomi
      ? "https://om.omni.se/ekonomi?utm_source=menu&utm_channel=ekonomi_web&utm_campaign=product_integrated&utm_content=button&utm_term=acquisition"
      : "https://om.omni.se/omni-mer?utm_source=menu&utm_channel=omni_web&utm_campaign=product_integrated&utm_content=button&utm_term=acquisition",
    type: "link",
    isPremium: true,
    isExternalLink: true,
  };
  const newsletter = {
    icon: "newsletter",
    text: "Nyhetsbrev",
    href: "https://omni.se/minsida/nyhetsbrev",
    type: "link",
    isExternalLink: true,
  };
  const logOut = {
    icon: "signout",
    onClick: logout,
    text: "Logga ut",
    type: "button",
  };
  const logIn = {
    icon: "signin",
    onClick: login,
    text: "Logga in",
    type: "button",
  };
  const appearance = AppearanceMenuItem();
  let title;
  let items;
  let pill;
  let footerContent;
  if (isLoggedIn) {
    title = `Hej ${name || email}!`;
    footerContent = (
      <>
        Du är inloggad med konto hos Schibsted.{" "}
        <a
          href="https://info.privacy.schibsted.com/se/vad-ar-ett-schibsted-konto/"
          target="_blank"
        >
          Läs mer om ditt konto
        </a>
      </>
    );
    if (isPremium) {
      pill = getBrandSpecificText("premiumName");
      items = [
        ...newsMix,
        membership,
        account,
        appearance,
        ...(isOmniEkonomi ? [newsletter] : []),
        logOut,
      ] as MenuItem[];
    } else {
      // Logged in, but not a premium user
      items = [
        ...newsMix,
        account,
        becomeMember,
        appearance,
        ...(isOmniEkonomi ? [newsletter] : []),
        logOut,
      ] as MenuItem[];
    }
  } else {
    // Not logged in, not premium
    title = "Ditt Omni";
    items = [
      ...newsMix,
      becomeMember,
      appearance,
      ...(isOmniEkonomi ? [newsletter] : []),
      logIn,
    ] as MenuItem[];
  }

  return (
    <MenuGroup
      title={title}
      items={items}
      pill={pill}
      footerContent={footerContent}
    />
  );
};

export { UserMenuGroup };
