import type { Ad as AdType } from "../../../types/ads/adResource";
import type { Article, Group } from "../../../types/content";
import type { AdResource } from "../../Ad/Ad";

import { BreakingCard } from "../../BreakingCard/BreakingCard";
import { InfoCard } from "../../InfoCard/InfoCard";
import { Teaser } from "../../Teaser/Teaser";
import { rawGroupToTeaserCluster } from "../../TeaserCluster/helpers/rawGroupToTeaserCluster";
import { TeaserCluster } from "../../TeaserCluster/TeaserCluster";
import { mapTeaserStyle } from "../utils/mapTeaserStyle";
import { ContentDisplayAd } from "../../Ad/ContentDisplay/ContentDisplay";
import { getMainMediaUrl } from "../utils/getMainMediaUrl";

const TopNewsLayout = ({
  data,
  contentDisplayAd,
  isFirstFeed,
}: {
  data: Group[];
  contentDisplayAd?: AdType;
  isFirstFeed?: boolean;
}) => {
  let currentNrOfClusters = 0;
  return data.map((feedGroup, index) => {
    if (!feedGroup?.[0]) return null;
    const articleElement =
      feedGroup[0]?.type === "Article" && (feedGroup[0] as Article);

    const isBreakingCard =
      articleElement && articleElement?.teaser_layout === "Flash";

    const isInfoCard =
      articleElement && articleElement?.teaser_layout === "Fishstick";

    const isContentDisplayAd =
      feedGroup[0].type === "NewsfeedAdPlaceholder" &&
      contentDisplayAd?.havePlacementRule;

    if (isContentDisplayAd) {
      return (
        <ContentDisplayAd
          contentDisplayResource={
            {
              ...feedGroup[0],
              ...contentDisplayAd,
              type: "Ad",
            } as AdResource
          }
          key={index}
        />
      );
    }

    if (isBreakingCard) {
      return (
        <BreakingCard
          articleLink={{
            articleId: articleElement.article_id,
            slug: articleElement.meta.promotion_content?.slug || "",
          }}
          vignette={articleElement.story_vignette.title}
          title={articleElement.title.value}
          key={`${articleElement.article_id} ${index}`}
        />
      );
    }

    if (isInfoCard) {
      const isNativeInfoCard = articleElement.meta.is_sponsored;

      return (
        <InfoCard
          article={articleElement}
          articleLink={{
            articleId: articleElement.article_id,
            slug: articleElement?.meta?.promotion_content?.slug || "",
          }}
          externalLink={
            articleElement.teaser_behaviour
              ? articleElement.teaser_behaviour[0].target
              : undefined
          }
          style={isNativeInfoCard ? "native" : "regular"}
          vignette={articleElement.story_vignette.title}
          title={articleElement.title.value}
          iconImageUrl={
            isNativeInfoCard
              ? getMainMediaUrl(articleElement.main_resource)
              : articleElement.story_vignette.icon_image?.url.light
          }
          key={`${articleElement.article_id} ${index}`}
          viewType="top"
        />
      );
    }

    const teaserStyle = mapTeaserStyle(feedGroup);
    const parsedData = rawGroupToTeaserCluster(feedGroup);

    if (!parsedData) return null;
    currentNrOfClusters++;
    const isSmallCluster =
      parsedData.articles.length === 1 && feedGroup[0]?.type === "Article";
    const isBox = parsedData.type === "Box";

    return (
      <TeaserCluster
        style={teaserStyle}
        header={parsedData.header}
        footer={parsedData.footer}
        key={`${parsedData.name} ${index}`}
        isSmallCluster={isSmallCluster}
        isBox={isBox}
      >
        {parsedData.articles.map((article, articleIndex) => {
          if (article.type !== "Article") {
            return null;
          }
          const shouldPrioritizeImages =
            (currentNrOfClusters === 1 && isFirstFeed) ||
            (article.main_resource?.type === "Image" &&
              !!article.main_resource?.watermarks);
          const isBox = teaserStyle === "premium";
          return (
            <Teaser
              article={article}
              hasPriorityImage={shouldPrioritizeImages}
              articlePositionInCluster={articleIndex + 1}
              positionInFeed={index + 1}
              key={`${article.article_id} ${index}`}
              inCluster
              isBox={isBox}
              isNative={teaserStyle === "native"}
              boxName={isBox ? parsedData?.name : undefined}
            />
          );
        })}
      </TeaserCluster>
    );
  });
};

export { TopNewsLayout };
