import Image from "next/image";
import cn from "classnames";

import type { Article } from "../../types/content";

import styles from "./InfoCard.module.scss";
import { Icon } from "../Icon/Icon";
import { InfoCardLink } from "./InfoCardLink";

interface InfoCardProps {
  article: Article;
  articleLink: {
    articleId: string;
    slug?: string;
  };
  title: string;
  vignette: string;
  iconImageUrl?: string;
  style: "native" | "regular";
  viewType: "top" | "latest";
  externalLink?: string;
}

const InfoCard = ({
  article,
  articleLink: { articleId, slug },
  vignette,
  title,
  iconImageUrl,
  style,
  viewType,
  externalLink,
}: InfoCardProps) => {
  return (
    <div
      className={cn([
        styles.infoCard,
        viewType === "latest" && styles.needSpace,
        styles[style],
      ])}
    >
      <InfoCardLink article={article} slug={slug} externalLink={externalLink}>
        {iconImageUrl && (
          <div className={cn([styles.imageContainer, styles[style]])}>
            <Image src={iconImageUrl} alt="" width={60} height={60} />
          </div>
        )}
        <div className={styles.textContainer}>
          <span>
            <span className={cn([styles.vignette, styles[style]])}>
              {vignette}
            </span>
            <span className={cn([styles.separator, styles[style]])}>●</span>
          </span>
          <span className={styles.title}>{title}</span>
          {style === "native" && (
            <div className={styles.nativeInfoCardAnnonsLabel}>Annons</div>
          )}
        </div>
        {style !== "native" && (
          <div className={styles.caretRightContainer}>
            <Icon variant="caretRight" className={styles.caretIcon} />
          </div>
        )}
      </InfoCardLink>
    </div>
  );
};

export type { InfoCardProps };
export { InfoCard };
