import type { Article } from "../../types/content";

import { getPulseTracker } from "..";

const newsroom: string =
  process.env.NEXT_PUBLIC_UI_THEME === "ekonomi" ? "omninext" : "omni";

const trackInfoCardImpression = ({ article }: { article: Article }) => {
  const pulseTracker = getPulseTracker();
  let baseUrl = pulseTracker?.pulseTracker.builders.origin?.url || "";

  const trailingSlash = baseUrl.slice(-1) === "/";
  if (trailingSlash) {
    baseUrl = baseUrl.slice(0, -1);
  }

  const accessLevel = article.style === "premium" ? "Paid" : "Free";
  const accessLevelName = article.style === "premium" ? "premium" : "free";
  const articleTitle = article.teaser.title.value;

  pulseTracker?.executePulseCommand("track", "trackerEvent", {
    object: {
      id: `infoCard-${article.article_id}`,
      type: "UIElement",
      elementType: "InfoCard",
      name: `${articleTitle}`,
      "spt:custom": {
        is_sponsored: article.meta?.is_sponsored,
        sponsor: article.sponsor,
        vignette: article.story_vignette?.title,
        story: article.story?.title,
        category: article.category.title,
      },
    },
    target: {
      id: `${article.article_id}`,
      type: "Article",
      name: `${articleTitle}`,
      url: article.teaser?.link
        ? article.teaser?.link
        : `${baseUrl}/a/${article.article_id}`,
      accessLevel,
      accessLevelName,
      newsroom,
    },
  });
};

export { trackInfoCardImpression };
