import cn from "classnames";

import type { AdInfoProps } from "./types";

import styles from "./AdInfo.module.scss";
import { Icon } from "../../Icon/Icon";

const AdInfo = ({
  creativeId,
  privacyUrl,
  closeModal,
  modalRef,
}: AdInfoProps) => (
  <div ref={modalRef} className={styles.container}>
    <div className={styles.info}>
      <div className={styles.infoHeader}>
        <div className={styles.close}>
          <span
            className={styles.closeSpan}
            onClick={(e) => {
              closeModal(e);
            }}
          >
            <Icon variant="close" size="small" />
          </span>
        </div>

        <h3 className={styles.modalTitle}>Varför ser du denna annons?</h3>
      </div>
      <p className={styles.modalText}>
        Du ser denna annons eftersom sajten du besöker till stor del finansieras
        av annonsintäkter. Vissa annonser ser du för att vi tror att de kan
        intressera dig. Detta antagande baserar vi på din tidigare aktivitet på
        Schibsteds sajter och appar.
      </p>
      <p className={styles.modalText}>
        Vi samarbetar också med vissa annonsörer om annonsprodukten Schibsted
        Match. Inom ramarna för Schibsted Match delar annonsören antingen
        kundernas e-postadress eller telefonnummer med Schibsted för att bygga
        en anpassad målgrupp. Under den processen skickar Schibsted inte
        användardata till annonsören.
      </p>

      <p className={styles.modalTextLink}>
        <a
          href="https://info.privacy.schibsted.com/se/gemensamt-personuppgiftsansvar-inom-ramen-for-schibsted-match/"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Läs mer om våra Schibsted Match-annonsörer.
        </a>
      </p>

      <h3 className={styles.modalTitle}>Hantera dina annonsinställningar</h3>

      <p className={styles.modalText}>
        Du kan ge ditt samtycke eller neka behandling av dina aktivitetsdata för
        anpassad annonsering via cookieinställningarna som du hittar på
        webbplatsen du besöker eller i inställningarna för appen du använder.
      </p>
      <p className={styles.modalText}>
        För alla andra typer av data, såsom kontoinformation kopplad till ditt
        Schibsted-konto, kan du uppdatera dina val för personanpassade annonser
        i sekretessinställningarna.
      </p>

      <p className={styles.modalTextLink}>
        <a
          href={privacyUrl}
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Uppdatera dina val för personanpassade annonser
        </a>
      </p>

      <h3 className={styles.modalTitle}>Om Schibsted Media och annonsering</h3>
      <p className={styles.modalTextLink}>
        <a
          href="https://info.privacy.schibsted.com/se/s005"
          className={styles.link}
          target="_blank"
          rel="noreferrer"
        >
          Läs mer om annonsering på Schibsted Media
        </a>
      </p>

      <div className={cn([styles.footer, styles.modalText])}>
        Omni är en del av{" "}
        <a
          href="https://schibsted.com/"
          className={styles.footer__link}
          target="_blank"
          rel="noreferrer"
        >
          Schibsted Media
        </a>
        <p className={cn([styles.footer__creative, styles.modalText])}>
          Annonsens ID-nummer: {creativeId}
        </p>
      </div>
    </div>
  </div>
);

export { AdInfo };
